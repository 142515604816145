<template>
    <div class="faq">
        <Header />
        <div class="banner">
            <div class="container">
                <div class="title">WAYS TO CHECK IF A CAR HAS A FINANCE</div>
            </div>
        </div>
        <div class="main">

            <div class="container">

                <div class="warpper">

                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h1>WAYS TO CHECK IF A CAR HAS A FINANCE</h1>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <p>Buying a used car or any other vehicle can be a tricky task. The automotive business is
                                filled with scammers and cons. To avoid such a devastating experience, one needs to have a
                                support of a credible authority that can assure safe passage for dealing and purchasing a
                                used car. It is effortless for someone new in the automotive dealing business to make a fool
                                out of him and rip him off heavily. Common people unknowingly are dragged into financial
                                fraud, and it takes years to come out of the financial debt they had to bear for someone
                                else's sin. </p>

                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>Buying a car without investigation:</h3>
                            <p>For example, if you have purchased a used car with no background knowledge of automotive
                                dealership and financing protocols for the used cars, it turns out the vehicle had
                                outstanding finance. Then it's highly likely that you will fall into this trap and lose both
                                the car and the money you spent on it because now you are indebted to pay the rest of the
                                outstanding finances. If a bank is a competent authority to which you owe the money, the
                                bank will repossess the car. For the sake of your financial security and to protect you from
                                this dirty business, some agencies can proofread and check the outstanding finances for your
                                desired car.</p>
                            <p>For this purpose, the car history report is a comprehensive report on the car's previous
                                history and alerts you for any potential risks associated with the particular car. It is
                                better to check vehicle history for the state, registration expiry date, stolen status and
                                plate, and any financial obligations via VIN. Likewise, the Personal property security
                                register (PPSR) is an agency that regulates security interests in personal property,
                                including vehicles. Check their website, fill in car chassis number or VIN and get a
                                detailed report about any discrepancy, including hidden charges. A PPSR certificate ensures
                                that there is any financial encumbrance on the car.</p>
                        </div>
                    </div>
                    <div class="ddRow">
                        <div class="ddCol-12">
                            <h3>How you can avoid these scams:</h3>
                            <p>The problem of such fraud exists because, legally, the private seller does not have to tell
                                you about any money the car is owing or airbag recall and if it has been written off. A
                                quick 2$ search on the PPSR can save you from a long term inconvenience as it will unravel
                                any security interest attached to the vehicle. Another way to buy a car with outstanding
                                finance on it is to ask the seller to pay out the loan before buying it. Moreover, other
                                organisations are working in every country to check the car's finances. Some of them include
                                carveto.com, revscheck.com and HPI car finance check. HPI car finance check is another
                                beneficial tool for this task.</p>
                            <p>Creating awareness about vehicle financial security is our responsibility, and everyone
                                should join hands in hands to eradicate any potential incident of car fraud. Scammers are on
                                the loose with the growing digital atmosphere and technology learning. Still, we can tackle
                                them if we know how to contact the proper authority and save ourselves from agony.</p>
                            <p>We have a <router-link to="/">team of experts</router-link> who can handle this job
                                efficiently and save you time and money most of all we can save you from any trouble.</p>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <Footer />
    </div>
</template>
  
<script>
import Header from "@/components/header/Header";
import Footer from "@/components/footer/Footer";
export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {};
    },
};
</script>
  
<style scoped lang="less">
.ddRow {
    font-family: Ubuntu-Regular, Ubuntu;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    margin: 1%;
    margin-bottom: 36px;
    width: 100%;
}

.ddRow h2,
.ddRow h1,
.ddRow h3 {
    font-weight: bold;
    color: #004AAD;
    margin: 4% 0 2% 0;
    text-align: center;
}

.ddRow ul li {
    list-style-type: disclosure-closed;
    margin-bottom: 2%;
    margin-left: 4%;
}

.ddRow img {
    width: 100%;
}

.ddCol-12 {
    width: 100%;
}

.ddCol-6 {
    width: 50%;
    padding: 2%;
    float: left;
}

.ddRow:after {
    content: "";
    display: table;
    clear: both;
}

@media only screen and (max-device-width: 960px) {
    .ddRow {
        display: block;
    }

    .ddCol-6 {
        width: 100%;
    }
}

.faq {
    width: 100%;
    position: relative;

    .banner {
        width: 100%;
        height: 286px;
        background: url("~@/assets/images/banner_3.png") no-repeat;
        background-size: cover;
        background-position: center;
        padding: 160px 0 80px 0;
        box-sizing: border-box;

        .title {
            font-size: 40px;
            font-family: Ubuntu-Bold, Ubuntu;
            font-weight: bold;
            color: #ffffff;
            line-height: 47px;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 690px) {
    .faq {
        .banner {
            height: 180px;
            padding: 84px 0 20px 0;

            .title {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}

@media only screen and (max-width: 1050px) and (min-width: 690px) {
    .faq {
        .banner {
            height: 180px;
            padding: 84px 0 20px 0;

            .title {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}</style>
  